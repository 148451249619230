import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import LoggerService from './LoggerService';
import ApplicationRoutes from "../../environment/AppRoutingName";

function GlobalPathTracker() {
    const location = useLocation();
    const routePaths = [
        ApplicationRoutes.root,
        ApplicationRoutes.maintenance,
        ApplicationRoutes.login,
        ApplicationRoutes.passwordRecoveryStart,
        ApplicationRoutes.passwordRecoveryEnd,
        ApplicationRoutes.registerStudents,
        ApplicationRoutes.registerProfessorsRo,
        ApplicationRoutes.registerProfessorsMd,
        ApplicationRoutes.registerParents,
        ApplicationRoutes.verify
    ];


    useEffect(() => {
        if (!routePaths.includes(location.pathname)) {
            console.log(location.pathname);
            new LoggerService().logPath(location.pathname);
        }
    }, [location.pathname]);

    return null;
}

export default GlobalPathTracker;