import React, {useEffect, useState} from "react";
import CoursesService from "../service/CoursesService";
import Course, {defaultCourse} from "../models/models/Course";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {IoMdPersonAdd} from "react-icons/io";
import Breadcrumbs from "../../../../../../../commons/component/breadcrumb/view/Breadcrumbs";
import ViewLessonCard from "../iterables/ViewLessonCard";
import Lesson from "../models/models/Lesson";
import ViewQuizCard from "../iterables/ViewQuizCard";
import Quiz from "../models/models/Quiz";
import LoadingPlaceholder from "../../../../../../../commons/component/loading/views/LoadingPlaceholder";
import LoadingCardPlaceholder from "../../../../../../../commons/component/loading/views/LoadingCardPlaceholder";
import User from "../../../../../../../commons/component/header/functionals/models/User";
import LoadingPlaceholderPersons from "../../../../../../../commons/component/loading/views/LoadingPlaceholderPersons";
import ViewUserCard from "../iterables/ViewUserCard";
import {IoAdd, IoClose, IoPersonAdd, IoSearch, IoTrashBin} from "react-icons/io5";
import {toast, Toaster} from "react-hot-toast";
import UserResponse from "../../../../../../../commons/component/header/functionals/models/UserResponse";
import BackButton from "../../../../../../../commons/component/BackButton";

export default function ViewCourse() {
    const [course, setCourse] = useState<Course>(defaultCourse);
    const [searchUsers, setSearchUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [isLoadingAddingTeachers, setIsLoadingAddingTeachers] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPersonsModal, setIsLoadingPersonsModal] = useState<boolean>(false);
    const [modal, setModal] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const {slug} = useParams<{ slug: string }>();
    const courseService = new CoursesService();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setIsLoading(true);
        if (slug?.length === 0 || slug === undefined) {
            navigate("/dashboard/admin/courses");
            return;
        }
        courseService.getCourse(slug).then((response) => {
            setCourse(response);
        })
            .catch((_) => setError(true))
            .finally(() => {
                setIsLoading(false);
            });
    }, [slug]);

    useEffect(() => {
        if (searchTerm.length !== 0) {
            const delayDebounceFn = setTimeout(() => searchUser(searchTerm), 500);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const searchUser = (userName: string) => {
        if (slug !== undefined) {
            setIsLoadingPersonsModal(true);
            courseService.searchForCourseUsers(userName, slug)
                .then((response) => setSearchUsers(response.map((u) => u.data)))
                .catch((_) => console.log(error))
                .finally(() => setIsLoadingPersonsModal(false));
        }
    }

    const setToTeachers = () => {
        if (slug !== undefined) {
            setIsLoadingAddingTeachers(true);
            courseService.addAllTeachers(slug)
                .then((_) => window.location.reload())
                .catch((_) => console.log(error))
                .finally(() => setIsLoadingAddingTeachers(false));
        }
    }

    const toggleModal = () => {
        setModal(!modal);
        setSearchTerm("");
    }

    const deleteUser = (slugUser: string) => {
        if (slug === undefined)
            return;

        courseService.removeUserToCourse(slug, slugUser)
            .then((response) => {
                toast.success(response.message);
                setCourse(prevState => {
                    return {
                        ...prevState,
                        users: prevState.users.filter(user => user.data.slug !== slugUser)
                    };
                });
            })
            .catch((error) => toast.error(error.response.data.message))
    }

    const addUser = (slugUser: string) => {
        if (slug === undefined)
            return;

        courseService.addUserToCourse(slug, slugUser)
            .then((response) => {
                toast.success(response.message);
                let tempUser: UserResponse = {
                    success: true,
                    data: searchUsers.filter((u) => u.slug === slugUser)[0],
                    message: ""
                };
                setSearchUsers(searchUsers.filter((u) => u.slug !== slugUser));
                setCourse(prevState => {
                    return {
                        ...prevState,
                        users: [...prevState.users, tempUser]
                    };
                });
            })
            .catch((error) => toast.error(error.response.data.message))
    }

    return (
        <div className={"px-3 md:px-8"}>
            <div><Toaster/></div>
            <Breadcrumbs paths={["Cursuri", course.courseName]}/>
            <div className={"flex justify-between"}>
                <h1 className="flex justify-center items-center gap-3 text-xl md:text-4xl font-bold">
                    <BackButton slug={slug ?? ''} isFull={false} isSkinny={true}/>
                    <p>Vizualizare curs</p>
                </h1>
            </div>
            <div className={"flex flex-col sm:flex-row w-full gap-5 mt-5"}>
                {
                    isLoading ?
                        <LoadingCardPlaceholder times={1}/> :
                        <div
                            className={"flex-col lg:sticky lg:top-[60px] sm:w-96 text-gray-700 bg-white shadow-md rounded-xl bg-clip-border h-fit"}>
                            <div className="rounded-t-lg h-[250px] relative overflow-hidden">
                                <img className="absolute inset-0 w-full h-full object-cover"
                                     src={course.courseImage}
                                     alt={course.courseName}/>
                            </div>
                            <div className={"p-5"}>
                                <h5 className="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {
                                        course.courseName
                                    }
                                </h5>
                                <p className="mb-3 font-normal text-gray-600 text-md md:text-lg">
                                    {
                                        course.courseDescription
                                    }
                                </p>
                            </div>
                        </div>
                }
                <div className={"w-full flex gap-0 lg:gap-5 flex-col lg:flex-row"}>
                    <div className={"w-full h-fit mb-5"}>
                        <div
                            className={"w-full p-5 flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border"}>
                            <h5 className="mb-5 text-lg md:text-2xl font-bold tracking-tight text-gray-900">
                                Desfasurator lectii si quiz-uri:
                            </h5>
                            <ol className="relative border-s border-gray-200 dark:border-gray-700">
                                {
                                    isLoading ?
                                        <LoadingPlaceholder/> :
                                        course.courseResources.map((cr, i) => {
                                            return cr.type === "lesson" ?
                                                <ViewLessonCard key={i} lesson={cr.object as Lesson}/> :
                                                <ViewQuizCard key={i} quiz={cr.object as Quiz}/>
                                        })
                                }
                            </ol>
                        </div>
                    </div>
                    <div className={"w-full h-fit mb-5"}>
                        <div
                            className={"w-full p-5 flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border"}>
                            <div className={"flex justify-between items-center mb-5"}>
                                <h5 className="text-lg md:text-2xl font-bold tracking-tight text-gray-900">
                                    Persoane care au access la acest curs:
                                </h5>
                                <div>
                                    <button onClick={toggleModal}
                                            className={"bg-accent-main-500 p-2 rounded-lg text-white"}>
                                        <IoAdd/>
                                    </button>
                                    <button onClick={setToTeachers}
                                            className={"bg-accent-main-500 p-2 rounded-lg text-white"}>
                                        <IoPersonAdd/>
                                    </button>
                                </div>
                            </div>
                            {
                                isLoading ?
                                    <LoadingPlaceholderPersons isCenter={false}/> :
                                    <ul className="w-full">
                                        {
                                            course.users.length === 0 ?
                                                <li>Nicio persoana nu are acces</li> :
                                                course.users.map((p, i) => <ViewUserCard color={"bg-error"}
                                                                                         user={p.data}
                                                                                         key={i}
                                                                                         action={deleteUser}
                                                                                         icon={<IoTrashBin/>}/>)
                                        }
                                    </ul>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="default-modal"
                 className={"overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full bg-black/80 " + (modal ? "flex" : "hidden")}>
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div
                            className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Adauga utilizatori
                            </h3>
                            <button type="button"
                                    onClick={toggleModal}
                                    className="text-gray-400 bg-transparent hover:bg-accent-main-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="default-modal">
                                <IoClose/>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">

                            <form className="flex items-center max-w-sm mx-auto">
                                <label htmlFor="simple-search" className="sr-only">Search</label>
                                <div className="relative w-full">
                                    <div
                                        className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <IoSearch/>
                                    </div>
                                    <input type="text" id="simple-search"
                                           onChange={handleSearchChange}
                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5"
                                           placeholder="Cauta utilizatorul dupa nume" required/>
                                </div>
                            </form>
                            {
                                isLoadingPersonsModal ?
                                    <LoadingPlaceholderPersons isCenter={true}/> :
                                    searchUsers.length === 0 ?
                                        <p>Nu exista utilizatori. Cauta din nou</p> :
                                        searchUsers.map((p, i) => <ViewUserCard color={"bg-green-500"}
                                                                                user={p}
                                                                                key={i}
                                                                                action={addUser}
                                                                                icon={<IoMdPersonAdd/>}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}